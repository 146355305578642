import { isHomepagePath } from '@seek/seek-jobs-seo';
import { useTranslations } from '@vocab/react';
import {
  Box,
  Button,
  Column,
  Columns,
  HiddenVisually,
  IconSearch,
  TextField,
} from 'braid-design-system';
import debounce from 'lodash/debounce';
import get from 'lodash/get';
import { type FormEventHandler, useCallback, useMemo } from 'react';

import NavLink from 'src/components/NavLink/NavLink';
import { ScreenReaderSkipTarget } from 'src/components/ScreenReaderSkipTarget/ScreenReaderSkipTarget';
import { useAppConfig } from 'src/config/appConfig';
import { useAnalyticsFacade } from 'src/modules/AnalyticsFacade';
import { getClassifications } from 'src/modules/refine-job-search';
import refineClassifications from 'src/modules/refine-job-search/refinements/classificationGraph';
import { isClientMobileWidth } from 'src/modules/responsive-helpers';
import { useDispatch, useSelector } from 'src/store/react';
import {
  runSearch,
  searchSubmit,
  whereSuggestionSelected,
} from 'src/store/search';
import {
  selectFeatureFlag,
  selectPathname,
  selectSearchQuery,
  selectWhereField,
} from 'src/store/selectors';
import { useLocalisedLinks } from 'src/utils/melwaysHelper';

import { getRefineSearchQuery } from '../utils/utils';

import translations from './.vocab';
import ClassificationsField from './ClassificationsField/ClassificationsField';
import KeywordField from './KeywordField/KeywordField';
import WhereField from './WhereField/WhereField';

import * as styles from './SearchBar.css';

export const correctBackdropPosition = (ref: null | HTMLElement) => {
  if (ref) {
    ref.style.left = '0px';
    ref.style.top = '0px';

    const { left, top } = ref.getBoundingClientRect();
    ref.style.left = `${left >= 0 ? '-' : ''}${left}px`;
    ref.style.top = `${top >= 0 ? '-' : ''}${top}px`;
  }
};

interface SearchBarProps {
  collapsed: boolean;
}

const useSearchPath = () => {
  const currentPath = useSelector(selectPathname);

  const [submitFormPath, currentSearchPath] = useLocalisedLinks([
    { path: '/jobs' },
    {
      path: isHomepagePath(currentPath) ? '/' : '/jobs',
    },
  ]);

  return { submitFormPath, currentSearchPath };
};

const SearchBar = (props: SearchBarProps) => {
  const { collapsed } = props;
  const { t } = useTranslations(translations);
  const dispatch = useDispatch();
  const analyticsFacade = useAnalyticsFacade();

  const { currentSearchPath, submitFormPath } = useSearchPath();

  const whereField = useSelector(selectWhereField);
  const searchQuery = getRefineSearchQuery(useSelector(selectSearchQuery));

  const { zone, language: languageCode } = useAppConfig();
  const cachedClassifications = getClassifications(zone, languageCode);

  const currentCounts = useSelector((state) => state.search.refinements.counts);
  const showSecondaryFilters = useSelector(
    selectFeatureFlag('secondaryFilters'),
  );

  const { classification: classifications } = useMemo(
    () =>
      refineClassifications(cachedClassifications, searchQuery, currentCounts),
    [cachedClassifications, searchQuery, currentCounts],
  );

  const selectedClassifications = useMemo(
    () => classifications.filter((item) => item.isActive),
    [classifications],
  );

  const immediateSearch = useCallback(
    (nextPathName: string = currentSearchPath) => {
      const action = runSearch({ pathname: nextPathName });
      dispatch(action);
    },
    [dispatch, currentSearchPath],
  );

  const debouncedSearch = useMemo(
    () => debounce(immediateSearch, 100, { leading: true }),
    [immediateSearch],
  );

  const onSubmit = useCallback<FormEventHandler>(
    (event) => {
      event.preventDefault();

      analyticsFacade.searchFormSubmitted();

      // @ts-expect-error This was the original implementation!
      dispatch(searchSubmit());

      debouncedSearch.cancel();
      immediateSearch(submitFormPath);

      if (
        isClientMobileWidth() &&
        document.activeElement &&
        document.activeElement instanceof HTMLElement
      ) {
        // NOTE: this will trigger KeywordField.onKeywordBlur -> debounceSearch (which triggers after 1s)
        // so we need to cancel it for the scenario that we're navigating
        // from home to /jobs
        document.activeElement.blur();
        debouncedSearch.cancel();
      }
    },
    [
      analyticsFacade,
      dispatch,
      debouncedSearch,
      immediateSearch,
      submitFormPath,
    ],
  );

  const onWhereSuggestionSelected = useCallback(
    (payload: Parameters<typeof whereSuggestionSelected>[0]) => {
      dispatch(whereSuggestionSelected(payload));
      debouncedSearch();
    },
    [dispatch, debouncedSearch],
  );

  const onWhereInputBlur = useCallback(() => {
    const { where = '' } = searchQuery;

    if (whereField.trim() !== where.trim()) {
      debouncedSearch();
    }
  }, [debouncedSearch, searchQuery, whereField]);

  const label = `${
    searchQuery.keywords
      ? t('{keywords} in', { keywords: searchQuery.keywords })
      : t('Jobs in')
  } ${get(selectedClassifications[0], 'label', t('All Classifications'))}`;

  return (
    <Box
      component="section"
      className={{
        [styles.searchBarRootIsCollapsed]: collapsed,
      }}
    >
      <Box
        display={{ mobile: collapsed ? 'block' : 'none', tablet: 'none' }}
        className={styles.maxWidthOnMobile}
      >
        <NavLink
          location={{ pathname: '/', query: searchQuery }}
          className={styles.summaryLink}
        >
          <TextField
            id="keywords-input-mobile"
            data={{ automation: 'keywords-input-mobile' }}
            aria-label="Click to refine search"
            icon={<IconSearch />}
            value={label}
            placeholder="Enter Keywords"
            onChange={() => {}}
          />
        </NavLink>
      </Box>

      <Box
        position="relative"
        display={collapsed ? { mobile: 'none', tablet: 'block' } : undefined}
        className={styles.maxWidthOnMobile}
      >
        <ScreenReaderSkipTarget name="start-of-content" />

        <form
          aria-labelledby="PerformASearch"
          role="search"
          name="SearchBar"
          id="SearchBar"
          method="get"
          action="/jobs"
          onSubmit={onSubmit}
        >
          <HiddenVisually>
            <h1 id="PerformASearch">{t('Perform a job search')}</h1>
          </HiddenVisually>

          <input
            type="hidden"
            name="classification"
            value={searchQuery.classification ?? ''}
          />
          <input
            type="hidden"
            name="subclassification"
            value={searchQuery.subclassification ?? ''}
          />

          <Columns
            space={{
              mobile: 'gutter',
              tablet: 'xxsmall',
            }}
            alignY="bottom"
            collapseBelow="tablet"
          >
            <Column width="3/5">
              <Columns space="xxsmall" alignY="bottom" collapseBelow="tablet">
                <Column>
                  <KeywordField
                    onSuggestionSelected={debouncedSearch}
                    onInputBlur={debouncedSearch}
                    onClear={debouncedSearch}
                  />
                </Column>

                {!showSecondaryFilters ? (
                  <Column>
                    <ClassificationsField
                      collapsed={collapsed}
                      classifications={classifications}
                      selectedClassifications={selectedClassifications}
                    />
                  </Column>
                ) : null}
              </Columns>
            </Column>
            <Column>
              <WhereField
                onSuggestionSelected={onWhereSuggestionSelected}
                onInputBlur={onWhereInputBlur}
                onClear={debouncedSearch}
              />
            </Column>
            <Column width="content">
              <Button
                id="searchButton"
                tone="brandAccent"
                type="submit"
                data={{
                  automation: 'searchButton',
                }}
                aria-label={t('Submit search')}
              >
                {t('SEEK')}
              </Button>
            </Column>
          </Columns>
        </form>
      </Box>
    </Box>
  );
};

export default SearchBar;
