import {
  Box,
  Text,
  IconClear,
  ButtonIcon,
  IconChevron,
  useResponsiveValue,
} from 'braid-design-system';
import { useState, useRef, useEffect } from 'react';

import {
  selectIsResultsLoading,
  selectPills,
} from 'src/store/results/selectors';

import { useSelector } from '../../../store/react.ts';

import * as styles from './DynamicPill.css.ts';
import { LoadingPills } from './LoadingPills/LoadingPills.tsx';

export const DynamicPill = () => {
  const pills = useSelector(selectPills);
  const [activeKeywords, setActiveKeywords] = useState<string[]>([]);
  const handleClick = (keyword: string) => {
    setActiveKeywords((prevActiveKeywords) =>
      prevActiveKeywords.includes(keyword)
        ? prevActiveKeywords.filter((k) => k !== keyword)
        : [...prevActiveKeywords, keyword],
    );
  };
  const isLoading = useSelector(selectIsResultsLoading);
  const ref = useRef<HTMLDivElement>(null);
  const [isAtStart, setIsAtStart] = useState(true);
  const [isAtEnd, setIsAtEnd] = useState(true);

  const handleScroll = () => {
    const carouselDiv = ref.current;
    if (!carouselDiv) return;

    const maxScrollLeft = carouselDiv.scrollWidth - carouselDiv.clientWidth;
    const newScrollLeft = carouselDiv.scrollLeft;

    setIsAtStart(newScrollLeft === 0);
    setIsAtEnd(newScrollLeft >= maxScrollLeft - 1);
  };

  const handleScrollLeft = (event: React.MouseEvent<HTMLElement>) => {
    event.preventDefault();
    const carouselDiv = ref.current;

    if (carouselDiv) {
      const scrollAmount = carouselDiv.clientWidth * 0.9;
      carouselDiv.scrollBy({ left: -scrollAmount, behavior: 'smooth' });
    }
  };

  const handleScrollRight = (event: React.MouseEvent<HTMLElement>) => {
    event.preventDefault();
    const carouselDiv = ref.current;

    if (carouselDiv) {
      const scrollAmount = carouselDiv.clientWidth * 0.9;
      carouselDiv.scrollBy({ left: scrollAmount, behavior: 'smooth' });
    }
  };

  useEffect(() => {
    const carouselDiv = ref.current;
    if (carouselDiv) {
      handleScroll(); // Initial position check
      carouselDiv.addEventListener('scroll', handleScroll);
      return () => {
        carouselDiv.removeEventListener('scroll', handleScroll);
      };
    }
  }, []);

  const isMobile =
    useResponsiveValue()({
      mobile: true,
      tablet: false,
    }) || false;

  return isLoading ? (
    <LoadingPills />
  ) : (
    <Box position="relative" display="flex" alignItems="center" width="full">
      <Box
        display={!isAtStart && !isMobile ? 'flex' : 'none'}
        position="relative"
        left={0}
        onClick={handleScrollLeft}
        zIndex={1}
      >
        <ButtonIcon
          variant="soft"
          id="scroll-left"
          label="scroll-left"
          icon={<IconChevron direction="left" />}
          tone="secondary"
          size="standard"
          bleed={false}
        />
      </Box>
      <Box
        display={!isAtStart && !isMobile ? 'flex' : 'none'}
        position="absolute"
        top={0}
        bottom={0}
        left={0}
        className={styles.fadeInLeft}
        pointerEvents="none"
      />
      <Box
        display="flex"
        overflow="scroll"
        ref={ref}
        onScroll={handleScroll}
        className={styles.hideScrollBar}
      >
        {pills?.map(({ label }, index) => (
          <Box key={index} paddingRight="small">
            <Box
              key={index}
              overflow="hidden"
              display="flex"
              alignItems="center"
              boxShadow="borderNeutralInverted"
              borderRadius="full"
              padding={{
                mobile: 'xsmall',
                tablet: 'small',
              }}
              className={styles.hoverPills}
              background={
                activeKeywords.includes(label) ? 'surface' : undefined
              }
              cursor="pointer"
              onClick={() => handleClick(label)}
            >
              {isMobile ? (
                <Text maxLines={1} size="xsmall">
                  {label.length >= 35
                    ? label.substring(0, 32).concat('...')
                    : label}
                </Text>
              ) : (
                <Text maxLines={1} size="small">
                  {label}
                </Text>
              )}
              {activeKeywords.includes(label) && (
                <Box paddingLeft="xxsmall">
                  <Text size="xsmall">
                    <IconClear />
                  </Text>
                </Box>
              )}
            </Box>
          </Box>
        ))}
      </Box>
      <Box
        display={!isAtEnd && !isMobile ? 'flex' : 'none'}
        position="absolute"
        top={0}
        bottom={0}
        right={0}
        className={styles.fadeInRight}
        pointerEvents="none"
      />
      <Box
        display={!isAtEnd && !isMobile ? 'flex' : 'none'}
        position="relative"
        right={0}
        onClick={handleScrollRight}
        zIndex={1}
      >
        <ButtonIcon
          variant="soft"
          id="scroll-right"
          label="scroll-right"
          icon={<IconChevron direction="right" />}
          tone="secondary"
          size="standard"
          bleed={false}
        />
      </Box>
    </Box>
  );
};
