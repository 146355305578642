import type { Zone } from '@seek/audience-zones';
import type { SearchParams } from '@seek/chalice-types';
// @ts-expect-error: non-ts file
import { encode as encodeLastSearchCookie } from '@seek/last-search-cookie';
import type { Language } from '@seek/melways-sites';
import { normaliseSearchQuery, qualifyUrl } from '@seek/seek-jobs-seo';
import { parse as parseCookies } from 'cookie';
import { set as localStorageSet } from 'local-storage';

import clean from 'src/modules/clean-object';
import { logger } from 'src/modules/logger';
import refine, {
  classificationCountsNeedUpdating,
} from 'src/modules/refine-job-search';
import createApiClient from 'src/modules/seek-jobs-api-client';
import { pushNavigate } from 'src/store/location';
import type { Country } from 'src/types/globals';
import { getCookieDomainFromHost } from 'src/utils/melwaysHelper';

import type { TypedAction, TypedThunkAction } from '../types';

import {
  GET_COUNTS_BEGIN,
  GET_COUNTS_ERROR,
  GET_COUNTS_SUCCESS,
  JOB_AREA_CLICKED,
  JOB_CLASSIFICATION_CLICKED,
  JOB_COMPANY_CLICKED,
  JOB_LOCATION_CLICKED,
  JOB_SUBCLASSIFICATION_CLICKED,
  JOB_SUBURB_CLICKED,
  QUICK_SEARCH_CITY_CLICKED,
  QUICK_SEARCH_CLASSIFICATION_CLICKED,
  QUICK_SEARCH_OTHER_CLICKED,
  SELECT_REFINEMENT,
  SELECT_WHERE,
  SUBMIT_SEARCH,
  TOGGLE_FILTERS,
  UPDATE_CRITERIA,
  UPDATE_KEYWORDS_FIELD,
  UPDATE_WHERE_FIELD,
  WHERE_FIELD_AUTOSUGGESTED_SELECTED,
  WHERE_FIELD_BLANKED,
  WHERE_FIELD_FOCUSED,
  WHERE_FIELD_RECENT_SELECTED,
  WHERE_FIELD_SUGGESTED_SELECTED,
  type Counts,
  type CountsApiResult,
  type CountsApiResultItem,
  type EmptyAction,
  type JobMetaClickAction,
  type QuickSearchCityClickedAction,
  type QuickSearchClassificationClickedAction,
  type QuickSearchOtherClickedAction,
  type SearchState,
  type SelectRefinementAction,
} from './types';

const api = createApiClient();

const KEYWORDS_LENGTH_LIMIT = 500;
const WHERE_LENGTH_LIMIT = 200;

export const initialState: SearchState = {
  keywordsField: '',
  whereField: '',
  filtersExpanded: false,
  query: {},
  lastQuery: {},
  hasLoadedCounts: false,
  refinements: {},
};

const updateRefinements = ({
  state,
  query,
  counts,
  zone,
  languageCode,
}: {
  state: SearchState;
  query?: SearchParams;
  counts?: Counts;
  zone: Zone;
  languageCode: Language;
}) => {
  const { query: currentQuery, refinements: currentRefinements } = state;
  const currentCounts = currentRefinements
    ? currentRefinements.counts
    : undefined;

  const newQuery = query || currentQuery;

  let newRefinements = {};

  try {
    newRefinements = refine({
      paramsUnsanitised: newQuery,
      newCounts: counts || currentCounts,
      lastParamsUnsanitised: currentQuery,
      lastCounts: currentCounts,
      zone,
      languageCode,
    });
  } catch (error) {
    logger.info(
      {
        error,
        variables: {
          newQuery,
          counts,
          currentCounts,
          currentQuery,
        },
      },
      'Error: refine function unable to process query',
    );
  }

  return {
    query: newQuery,
    refinements: newRefinements,
  };
};

const enforceInputLengthLimit = (input = '', length: number) =>
  input.substring(0, length);

export function reducer(
  state: SearchState = initialState,
  action: TypedAction,
): SearchState {
  switch (action.type) {
    case TOGGLE_FILTERS: {
      const { filtersExpanded } = action.payload;
      return {
        ...state,
        filtersExpanded,
      };
    }

    case SUBMIT_SEARCH: {
      return {
        ...state,
        filtersExpanded: true,
      };
    }

    case UPDATE_CRITERIA: {
      const { lastQuery, query, zone, languageCode } = action.payload;

      return {
        ...state,
        ...updateRefinements({
          state,
          query,
          zone,
          languageCode,
        }),
        keywordsField: enforceInputLengthLimit(
          query.keywords,
          KEYWORDS_LENGTH_LIMIT,
        ),
        whereField: enforceInputLengthLimit(query.where, WHERE_LENGTH_LIMIT),
        lastQuery,
      };
    }

    case GET_COUNTS_BEGIN: {
      const { query, zone, languageCode } = action.payload;

      return {
        ...state,
        ...updateRefinements({
          state,
          query,
          zone,
          languageCode,
        }),
      };
    }

    case GET_COUNTS_SUCCESS: {
      const { result, zone, languageCode } = action.payload;

      const counts: Counts = {};
      result.counts.forEach((facet: CountsApiResultItem) => {
        counts[facet.name] = facet.items;
      });

      return {
        ...state,
        ...updateRefinements({
          state,
          counts,
          zone,
          languageCode,
        }),
        hasLoadedCounts: true,
      };
    }

    case GET_COUNTS_ERROR: {
      const { zone, languageCode } = action.payload;
      const counts = {};

      return {
        ...state,
        ...updateRefinements({
          state,
          counts,
          zone,
          languageCode,
        }),
      };
    }

    case UPDATE_KEYWORDS_FIELD: {
      const { keywordsField } = action.payload;

      return {
        ...state,
        keywordsField,
      };
    }

    case UPDATE_WHERE_FIELD: {
      const { whereField } = action.payload;

      return {
        ...state,
        whereField,
      };
    }

    default: {
      return state;
    }
  }
}

export default reducer;

export const updateCriteria =
  ({
    path,
    query = {},
    zone,
  }: {
    path: string;
    query?: SearchParams;
    zone: Zone;
  }): TypedThunkAction =>
  (dispatch, getState) => {
    const {
      search: { query: lastQuery },
      appConfig: { language: languageCode },
    } = getState();

    const normalisedQuery = normaliseSearchQuery({ path, query });
    const tags = Object.keys(normalisedQuery).map(
      (paramName) => `Search Criteria: ${paramName}`,
    );
    dispatch({
      type: UPDATE_CRITERIA,
      payload: {
        lastQuery,
        query: normalisedQuery,
        zone,
        languageCode,
      },
      meta: { hotjar: tags },
    });
  };

export const getCounts =
  ({
    country,
    zone,
    path,
    query = {},
    cookies,
  }: {
    country: Country;
    zone: Zone;
    path: string;
    query?: SearchParams;
    cookies: Record<string, string>;
  }): TypedThunkAction =>
  (dispatch, getState) => {
    const state = getState();
    const {
      location: { requestId },
      search: { hasLoadedCounts, lastQuery },
      user: { testHeaders },
      appConfig: { language: languageCode, locale },
    } = state;
    const searchParams = normaliseSearchQuery({ path, query });

    // First, let's see if we even need to get counts at all...
    if (
      hasLoadedCounts &&
      !classificationCountsNeedUpdating(lastQuery, searchParams)
    ) {
      return Promise.resolve(); // Skip loading counts
    }
    dispatch({
      type: GET_COUNTS_BEGIN,
      payload: {
        query: searchParams,
        zone,
        languageCode,
      },
    });

    return api.jobs
      .counts({
        searchParams,
        zone,
        cookies,
        requestId,
        country,
        testHeaders,
        locale,
      })
      .then((result: CountsApiResult) => {
        dispatch({
          type: GET_COUNTS_SUCCESS,
          payload: {
            result,
            zone,
            languageCode,
          },
        });
      })
      .catch((err: Error) => {
        dispatch({
          type: GET_COUNTS_ERROR,
          error: true,
          payload: {
            err,
            zone,
            languageCode,
          },
        });
      });
  };

const setLastSearchWhere = (val: string) =>
  localStorageSet('lastSearchWhere', val);

export const persistCriteriaLocally =
  (): TypedThunkAction => (dispatch, getState) => {
    const {
      search: { query },
      location: { hostname },
      results: { location: locationState },
    } = getState();

    const {
      whereId: whereid,
      locationId: location,
      areaId: area,
    } = locationState || {};

    const recentSearchesCookie = parseCookies(document.cookie, {
      // do not decode,
      // so that the non latin chars in previous searches will be sent as encoded
      decode: (_) => _,
    }).main;
    const cookieValue = encodeLastSearchCookie(
      new Date(),
      {
        ...query,
        whereid,
        location,
        area,
      },
      recentSearchesCookie,
    );

    const cookieDomain = getCookieDomainFromHost(hostname);

    const cookiePath = '/';

    const expirationDate = new Date();
    expirationDate.setFullYear(expirationDate.getFullYear() + 1);
    const cookieExpires = expirationDate.toUTCString();

    document.cookie = `main=${cookieValue};domain=${cookieDomain};path=${cookiePath};expires=${cookieExpires}`;

    if (query.where) {
      setLastSearchWhere(query.where);
    }
  };

export function quickSearchClassificationClicked(): QuickSearchClassificationClickedAction {
  return {
    type: QUICK_SEARCH_CLASSIFICATION_CLICKED,
    meta: { hotjar: 'Quick Search Classification Clicked' },
  };
}

export function quickSearchCityClicked(): QuickSearchCityClickedAction {
  return {
    type: QUICK_SEARCH_CITY_CLICKED,
    meta: { hotjar: 'Quick Search City Clicked' },
  };
}

export function quickSearchOtherClicked(
  keywords: string,
): QuickSearchOtherClickedAction {
  return {
    type: QUICK_SEARCH_OTHER_CLICKED,
    meta: { hotjar: `Quick Search ${keywords} Clicked` },
  };
}

type JobMetaClickActionCreator = (
  type:
    | 'company'
    | 'suburb'
    | 'location'
    | 'area'
    | 'classification'
    | 'subClassification',
) => JobMetaClickAction;

export const jobMetaClick: JobMetaClickActionCreator = (type) => {
  switch (type) {
    case 'area': {
      return {
        type: JOB_AREA_CLICKED,
        meta: { hotjar: 'Job Area Clicked' },
      };
    }
    case 'classification': {
      return {
        type: JOB_CLASSIFICATION_CLICKED,
        meta: { hotjar: 'Job Classification Clicked' },
      };
    }
    case 'company': {
      return {
        type: JOB_COMPANY_CLICKED,
        meta: { hotjar: 'Job Company Clicked' },
      };
    }
    case 'location': {
      return {
        type: JOB_LOCATION_CLICKED,
        meta: { hotjar: 'Job Location Clicked' },
      };
    }
    case 'suburb': {
      return {
        type: JOB_SUBURB_CLICKED,
        meta: { hotjar: 'Job Suburb Clicked' },
      };
    }
    case 'subClassification': {
      return {
        type: JOB_SUBCLASSIFICATION_CLICKED,
        meta: { hotjar: 'Job Subclassification Clicked' },
      };
    }
  }
};

export function updateKeywordsField(keywordsField: string): TypedAction {
  return {
    type: UPDATE_KEYWORDS_FIELD,
    payload: { keywordsField },
  };
}

export function whereFieldFocused(): EmptyAction {
  return {
    type: WHERE_FIELD_FOCUSED,
  };
}

export const updateWhereField =
  (whereField: string): TypedThunkAction =>
  (dispatch) => {
    if (whereField === '') {
      dispatch({
        type: WHERE_FIELD_BLANKED,
      });
    }

    dispatch({
      type: UPDATE_WHERE_FIELD,
      payload: { whereField },
    });
  };

export function selectWhere() {
  return {
    type: SELECT_WHERE,
    meta: {
      metrics: {
        name: SELECT_WHERE,
      },
    },
  };
}

export function selectRefinement(refinement: string): SelectRefinementAction {
  return {
    type: SELECT_REFINEMENT,
    meta: {
      metrics: {
        name: SELECT_REFINEMENT,
        tags: { refinement },
      },
    },
  };
}

export function searchSubmit() {
  return {
    type: SUBMIT_SEARCH,
    meta: {
      metrics: {
        name: SUBMIT_SEARCH,
      },
    },
  };
}

const retainSavedSearchId = (
  query: SearchParams,
  keywordsField: string,
  whereField: string,
) => {
  if (
    query.savedsearchid &&
    ((query.keywords === undefined && keywordsField !== '') ||
      (query.keywords !== undefined && query.keywords !== keywordsField) ||
      (query.where === undefined && whereField !== '') ||
      (query.where !== undefined && query.where !== whereField))
  ) {
    return null;
  }
  return query.savedsearchid;
};

export const runSearch =
  ({ pathname }: { pathname?: string }): TypedThunkAction =>
  (_dispatch, getState) => {
    const state = getState();
    const {
      location: locationStore,
      search: { whereField, query, keywordsField },
    } = state;
    const { isHomepage } = locationStore;
    const companysearch =
      query.companysearch && keywordsField === query.keywords;

    const defaultPathname = isHomepage ? '/' : '/jobs';
    const savedsearchid = retainSavedSearchId(query, keywordsField, whereField);

    const cleanQuery = clean({
      ...query,
      advertiserid: null,
      keywords: keywordsField,
      companysearch,
      where: whereField,
      page: null, // Reset page parameter
      sortmode: keywordsField.trim() ? query.sortmode : null,
      savedsearchid,
    });

    const { path: qualifiedPath, query: qualifiedQuery } = qualifyUrl({
      path: pathname || defaultPathname,
      query: cleanQuery,
      filteredEncoding: true,
    });
    const location = {
      pathname: qualifiedPath,
      query: qualifiedQuery,
    };
    pushNavigate({ location });
  };

export const whereSuggestionSelected =
  ({
    suggestion,
    suggestionValue,
  }: {
    suggestion: any;
    suggestionValue: string;
  }): TypedThunkAction =>
  (dispatch) => {
    dispatch(updateWhereField(suggestionValue));

    if (typeof suggestion !== 'undefined') {
      switch (suggestion.analyticsType) {
        case 'remote': {
          return dispatch({
            type: WHERE_FIELD_RECENT_SELECTED,
            meta: { hotjar: 'Where Field Remote Selected' },
          });
        }
        case 'recent': {
          return dispatch({
            type: WHERE_FIELD_RECENT_SELECTED,
            meta: { hotjar: 'Where Field Recent Selected' },
          });
        }

        case 'related area':
        case 'related areas':
        case 'suggested area':
        case 'suggested areas': {
          return dispatch({
            type: WHERE_FIELD_SUGGESTED_SELECTED,
            meta: { hotjar: 'Where Field Suggested Selected' },
          });
        }

        default: {
          return dispatch({
            type: WHERE_FIELD_AUTOSUGGESTED_SELECTED,
            meta: { hotjar: 'Where Field Autosuggested Selected' },
          });
        }
      }
    }
  };

export const expandSearchFilters = (filtersExpanded: boolean): TypedAction => ({
  type: TOGGLE_FILTERS,
  payload: { filtersExpanded },
});
