import { useCallback, useEffect } from 'react';

import { urlLocationToRouterLocation } from 'src/client/locationTransforms';
import { scrollTo } from 'src/modules/scroll-with-callback';
import { history } from 'src/store/location';
import { useSelector } from 'src/store/react';
import {
  selectAuthenticated,
  selectIsJDP,
  selectJobDetailsResult,
  selectLocation,
  selectResults,
  selectSelectedJobId,
} from 'src/store/selectors';

import { useSavedJobsData } from './useSavedJobsData';
import { useToggleSavedJob } from './useToggleSavedJob';

const SAVE_JOB_QUERY_PARAM = 'savejob';

export const useSaveJobFromQueryParam = () => {
  const currentLocation = useSelector(selectLocation);
  const { [SAVE_JOB_QUERY_PARAM]: saveJobId, ...query } =
    currentLocation?.query;
  const jobId = parseInt(saveJobId, 10);

  const foundJob = useSelector(selectResults)?.jobs?.find(
    (item) => item.id === jobId,
  );
  const jobDetails = useSelector(selectJobDetailsResult)?.job;

  const isAuthenticated = useSelector(selectAuthenticated);

  const isJDP = useSelector(selectIsJDP);
  const job = isJDP ? jobDetails : foundJob;

  const savedJobs = useSavedJobsData({
    isJobDetailsView: isJDP,
    shouldSkip: !Boolean(job),
  });
  const { toggleSavedJob } = useToggleSavedJob({
    jobId: String(jobId) || '',
    savedJobsData: savedJobs?.data,
    forceSave: true,
    ...(isJDP
      ? {
          linkPosition: 'job details',
          view: 'jobDetails',
        }
      : {
          linkPosition: 'listing',
          view: 'serp',
          jobTracking: foundJob?.tracking,
        }),
  });

  const clearSaveJobQueryParam = useCallback(() => {
    history.replace(urlLocationToRouterLocation({ ...location, query }));
  }, [query]);

  const preSelectedJobId = useSelector(selectSelectedJobId);
  const scrollToJob = useCallback(() => {
    if (
      !isJDP &&
      // This is to prevent the scroll in SplitView in case there's a pre-selected job
      // i.e. /jobs?jobId=123&savejob=123
      !preSelectedJobId
    ) {
      const savedJobElement = document.querySelectorAll(
        `[data-job-id="${jobId}"]`,
      )[0];

      if (savedJobElement) {
        scrollTo({
          top: savedJobElement.getBoundingClientRect().top,
        });
      }
    }
  }, [jobId, isJDP, preSelectedJobId]);

  useEffect(() => {
    if (
      job &&
      // We need to check if the job ID is the same as the savejob ID ..
      // .. to avoid saving another job when visiting JDP with savejob query param
      String(job.id) === String(saveJobId) &&
      isAuthenticated
    ) {
      toggleSavedJob();
      scrollToJob();
    }

    // Cleaning the savejob query param regardless
    if (saveJobId) {
      clearSaveJobQueryParam();
    }
    // We actually want to run this effect only once, hence the empty dependency array
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
};
