import type { UrlLocation } from '@seek/chalice-types';
import type { Hubble } from '@seek/hubble/lib/Hubble';
import { metrics } from '@seek/metrics-js';
import type { Action, Location } from 'history';
import isEmpty from 'lodash/isEmpty';
import { matchRoutes } from 'react-router-config';

import {
  type AnalyticsFacade,
  isBrowserAnalyticsFacade,
} from 'src/modules/AnalyticsFacade';
import { setHubbleLoginId } from 'src/modules/hubble';
import { logger } from 'src/modules/logger';
import {
  jobDetailsPageRegex,
  searchResultsPageRegex,
} from 'src/modules/routes-regexp';
import { updateUserTestData } from 'src/modules/seek-jobs-api-client/apis/candidate';
import appRoutesConfig from 'src/routes';
import type createStore from 'src/store/createStore';
import { locationChanged } from 'src/store/location';
import {
  selectHostname,
  selectLocation,
  selectSearchPerformedAnalyticsData,
  selectAuthenticated,
} from 'src/store/selectors';
import { pageLoaded } from 'src/store/ui';
import { getAuthenticatedStatus, updateAuthenticated } from 'src/store/user';

import { fetchDataForRoutes } from './fetchDataForRoutes';
import { routerLocationToUrlLocation } from './locationTransforms';

interface Options {
  analyticsFacade: AnalyticsFacade;
  store: ReturnType<typeof createStore>;
  apolloClient: Parameters<typeof fetchDataForRoutes>[0]['apolloClient'];
  visitorId: string;
  hubble: Hubble;
}

export const createLocationChangeHandler = (options: Options) => {
  const { analyticsFacade, store, apolloClient, visitorId, hubble } = options;
  const { dispatch } = store;
  const onLocationChanged = (
    location: Location<UrlLocation['state']>,
    action?: Action,
  ): void => {
    if (action === 'REPLACE') {
      return;
    }

    metrics.count('pageload');
    const state = store.getState();
    const currentLocation = selectLocation(state);
    const hostname = selectHostname(state);
    const nextLocation = routerLocationToUrlLocation(location);
    const {
      pathname: currentPathname,
      query: {
        page: currentPageNumber,
        advertiserid: currentAdvertiserId,
      } = {},
    } = currentLocation;
    const {
      pathname: nextPathname,
      query: { page: nextPageNumber, advertiserid: nextAdvertiserId } = {},
    } = nextLocation;

    const isJobDetails = jobDetailsPageRegex.test(nextPathname!);

    const didPageChange =
      currentPathname !== nextPathname ||
      currentPageNumber !== nextPageNumber ||
      // This is going to handle the case where the "advertiserId" changes on empty search. Since the path will not change for this case
      currentAdvertiserId !== nextAdvertiserId ||
      isJobDetails;

    if (action !== 'POP' && didPageChange) {
      window.document.body.scrollIntoView();
    }

    const matchedRoutes = matchRoutes(
      appRoutesConfig(state.appConfig.site),
      nextPathname!,
    ).map(({ route }) => route);
    const authenticated = selectAuthenticated(state);
    const { search } = store.getState();

    const routeHasChanged =
      action === undefined || currentPathname !== nextPathname;

    dispatch(pageLoaded(!routeHasChanged));
    dispatch(locationChanged(window.location.href, nextLocation));

    /*
      If this handler is called during page load, we do not want
      to call the location changed analytics event as it will prematurely
      populate incorrect data layer properties (eg. previousSearchId).
      This is a stop gap solution in response to https://myseek.atlassian.net/browse/DCS-7030.

      We are using this short term solution as refactoring the boot sequence is a
      much bigger undertaking that requires a deeper investigation. An investigation ticket
      has been created here: https://myseek.atlassian.net/browse/DCS-7331
    */
    if (typeof action !== 'undefined') {
      analyticsFacade.locationChanged();
    }

    const isBackToSerpFromJdp =
      action === 'POP' &&
      jobDetailsPageRegex.test(currentPathname) &&
      searchResultsPageRegex.test(nextPathname!);

    if (!isBackToSerpFromJdp || isEmpty(search.refinements)) {
      const waitForAuth = async () => {
        if (!authenticated) {
          metrics.count(`auth.event`, [
            `lastKnownSolIdNotSet:${authenticated}]`,
          ]);
          const resolvedAuthState = await getAuthenticatedStatus();
          await updateUserTestData(store, resolvedAuthState, hostname);
          const payload = { authenticated: resolvedAuthState };
          dispatch(updateAuthenticated(payload));
          analyticsFacade.userDetailsUpdated(payload);
          setHubbleLoginId(hubble, store);
          return resolvedAuthState;
        }
        return authenticated;
      };

      fetchDataForRoutes({
        analyticsFacade,
        matchedRoutes,
        location: nextLocation,
        dispatch,
        getState: store.getState,
        apolloClient,
        waitForAuth,
        visitorId,
      }).catch((err: Error) => {
        logger.error(err);
      });
    }

    if (isBackToSerpFromJdp) {
      const payload = selectSearchPerformedAnalyticsData(state);
      analyticsFacade.searchPerformed(payload);

      if (isBrowserAnalyticsFacade(analyticsFacade)) {
        analyticsFacade.searchResultsPageLoaded(
          {
            solMetadata: state.results.solMetadata,
          },
          {
            flightId: state.results.solMetadata?.tags?.mordor__flights ?? '',
          },
        );
      }
    }
  };

  return onLocationChanged;
};
