import type { Zone } from '@seek/audience-zones';

import type { TFeatureFlagNames } from '../featureFlags/types';

/**
 * See https://myseek.atlassian.net/wiki/spaces/TDA/pages/2056519973/Experimentation
 * for experiment specs.
 */

interface TSeekExperimentLink {
  href: string;
}

export type TExperimentTrafficName = 'variant' | 'control' | 'outsider';
export type TExperimentTrafficIndex = '0' | '1' | '2';

export type TExperimentKeys = TFeatureFlagNames | 'remoteSearchFilter';

export interface VariationType {
  name: TExperimentTrafficName;
  index: TExperimentTrafficIndex;
}

export type TSeekExperiments = Partial<
  Record<TExperimentKeys, TSeekExperiment>
>;

export interface TSeekExperiment {
  name: TExperimentNames;
  variation: VariationType;
  num: number;
  _links?: {
    conversion: TSeekExperimentLink;
    impression: TSeekExperimentLink;
  };
}

export type TExperimentNames =
  | 'split_view_anz_6'
  | 'remote_search_filter'
  | 'logged_out_recs'
  | 'dynamic_pills'
  | 'secondary_filters'
  | 'serp_job_card_info_density_1';

export const EXPERIMENTS_INITIALISED = '__EXPERIMENTS_INITIALISED';

export interface TExperiment {
  name: TExperimentNames;
  percentage: number;
  num: number;
  targetZones: Set<Zone>;
  groupSalt?: string;
  enableTrackingForZeroPercent?: boolean;
}

export type TFeatureExperiments = Partial<Record<TExperimentKeys, TExperiment>>;

export interface UpdateExperimentsAction {
  type: typeof EXPERIMENTS_INITIALISED;
  payload: TSeekExperiments;
}

export type ExperimentsState = TSeekExperiments;
