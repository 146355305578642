import type {
  Description,
  SearchParams,
  SearchResult,
  SearchResultJob,
  SearchResultLocation,
  SearchResultSortMode,
  Facets,
  Pills,
} from '@seek/chalice-types';

import type { GetRecommendations_viewer_recommendations_recommendedGlobalJobs_globalJobs } from 'src/modules/graphql/queries/types/GetRecommendations';
import type { LoggedOutRecommendation } from 'src/modules/hooks/useLoggedOutRecommendations';
import type {
  SearchViewModelHeader,
  SearchViewModelSuggestions,
} from 'src/types/globals';

import type { Meta } from '../meta';
import type { SUBMIT_SEARCH } from '../search/types';

export const MORE_OPTIONS = 'MORE_OPTIONS';
export const GET_RESULTS_BEGIN = 'GET_RESULTS_BEGIN';
export const CLEAR_RESULTS = 'CLEAR_RESULTS';
export const GET_RESULTS_SUCCESS = 'GET_RESULTS_SUCCESS';
export const GET_RESULTS_ERROR = 'GET_RESULTS_ERROR';
export const GET_RESULTS_ERROR_CANCELLATION = 'GET_RESULTS_ERROR_CANCELLATION';
export const FETCH_SEARCH_RESULTS_ERROR = 'FETCH_SEARCH_RESULTS_ERROR';
export const RESULT_LINK_CLICKED = 'RESULT_LINK_CLICKED';
export const JORA_RESULT_LINK_CLICKED = 'JORA_RESULT_LINK_CLICKED';
export const EMPTY_RELATED_SEARCH: RelatedSearch[] = [];
export const SAVE_SOL_REFERENCES = 'SAVE_SOL_REFERENCES';
export const SAVE_SOL_REFERENCES_FOR_LOGGED_OUT_HOME_RECS =
  'SAVE_SOL_REFERENCES_FOR_LOGGED_OUT_HOME_RECS';
export const SAVE_SOL_REFERENCES_FOR_LOGGED_IN_HOME_RECS =
  'SAVE_SOL_REFERENCES_FOR_LOGGED_IN_HOME_RECS';
export const SET_SELECTED_JOB_ID = 'SET_SELECTED_JOB_ID';

export interface SearchViewModelSummary {
  displayTotalCount: string;
  text: string;
}

export interface SearchViewModelCompanySuggestion {
  search: {
    keywords?: string;
    companyName?: string;
  };
  count: string;
}

interface Jobs {
  jobs: SearchResultJob[] | null;
  tiers?: Record<string, Pick<Jobs, 'jobs'>>;
}

export interface SearchViewModel extends Jobs {
  searchParams: SearchParams;
  isLoading: boolean;
  isZeroResults: boolean;
  summary: SearchViewModelSummary | null;
  header: SearchViewModelHeader | null;
  suggestions: SearchViewModelSuggestions | null;
  companySuggestion?: SearchViewModelCompanySuggestion;
  canonicalCompany?: Description;
  hasHeaderBeforeJobs: boolean;
  pills?: Pills;
}

export type EnrichedSearchViewModel = SearchViewModel & {
  suburbs: string[];
};

export interface RelatedSearch {
  keywords: string;
  totalJobs: number;
  type?: string;
}

export interface FacetType {
  id: string;
  count: number;
  label: string;
}

export type SolReferenceKeyLookup = {
  hash: string;
  // Extending this type to include displayType for logged out recs since this is only to be use inside of Chalice
  displayType:
    | SearchResultJob['displayType']
    | 'loggedOutHomeRecs'
    | 'loggedInHomeRecs';
} & Pick<SearchResultJob, 'id'>;

export interface ResultsState {
  results: EnrichedSearchViewModel | null;
  isLoading: boolean;
  isError: boolean;
  totalPages: number;
  source: string;
  canonicalCompany?: Description;
  companySuggestion?: SearchViewModelCompanySuggestion;
  location?: SearchResultLocation;
  locationWhere: string | null;
  joraCrossLink?: any;
  title: string;
  totalCount: number | null;
  jobIds: string[];
  selectedJobId?: string;
  lastPage?: number;
  paginationParameters: Record<string, unknown>;
  sortMode: SearchResultSortMode[] | null;
  hidden: boolean;
  relatedSearches: RelatedSearch[];
  userQueryId?: string;
  uniqueSearchToken?: string;
  solMetadata?: {
    jobId: number;
    token: string;
    tags: { ['mordor__flights']: string };
    locationDistance?: number;
  };
  solMetadataString?: string;
  solReferenceKeys: SolReferenceKeyLookup[];
  isRadialFilterShown?: boolean;
  isRadialFilterNudgeShown?: boolean;
  facets?: Facets;
  pills?: Pills;
}

export interface GetResultsSuccessAction {
  type: typeof GET_RESULTS_SUCCESS;
  payload: {
    result: SearchResult;
    query: SearchParams;
    currentPageNumber?: number;
    userQueryId: any;
    relatedSearches: RelatedSearch[];
  };
  meta: Meta;
}

export interface SaveSolReferencesAction {
  type: typeof SAVE_SOL_REFERENCES;
}

export interface SaveSolReferencesForLoggedOutRecsAction {
  type: typeof SAVE_SOL_REFERENCES_FOR_LOGGED_OUT_HOME_RECS;
  payload: { recommendations: LoggedOutRecommendation[] };
}

export interface SaveSolReferencesForLoggedInRecsAction {
  type: typeof SAVE_SOL_REFERENCES_FOR_LOGGED_IN_HOME_RECS;
  payload: {
    recommendations: GetRecommendations_viewer_recommendations_recommendedGlobalJobs_globalJobs[];
  };
}

export interface SetSelectedJobIdAction {
  type: typeof SET_SELECTED_JOB_ID;
  payload: {
    jobId?: string;
  };
}

export type ResultsAction =
  | {
      type: typeof CLEAR_RESULTS;
      payload?: Record<string, unknown>;
    }
  | GetResultsSuccessAction
  | {
      type: typeof GET_RESULTS_BEGIN;
      payload: {
        query: SearchParams;
        metrics: any;
      };
    }
  | {
      type: typeof GET_RESULTS_ERROR;
      payload?: Record<string, unknown>;
    }
  | {
      type: typeof SUBMIT_SEARCH;
      payload?: Record<string, unknown>;
    }
  | {
      type: typeof MORE_OPTIONS;
      payload?: Record<string, unknown>;
      meta: Meta;
    }
  | {
      type: typeof RESULT_LINK_CLICKED;
      meta: Meta;
    }
  | {
      type: typeof FETCH_SEARCH_RESULTS_ERROR;
      meta: Meta;
    }
  | {
      type: typeof GET_RESULTS_ERROR_CANCELLATION;
      meta: Meta;
    }
  | {
      type: typeof JORA_RESULT_LINK_CLICKED;
      meta: Meta;
    }
  | SaveSolReferencesAction
  | SaveSolReferencesForLoggedOutRecsAction
  | SaveSolReferencesForLoggedInRecsAction
  | SetSelectedJobIdAction;
