import { combineReducers } from 'redux';
// @ts-expect-error: non-ts file
import { reducer as createHotjarReducers } from 'redux-hotjar';

import { reducer as experiments } from 'src/store/experiments';

import appConfig from './appConfig';
import banner from './banner';
import featureFlags from './featureFlags';
import jobdetails from './jobdetails';
import lmis from './lmis';
import location from './location';
import nudges from './nudges';
import recentSearches from './recentSearches';
import results from './results';
import saveSearch from './saveSearch';
import search, { type reducer as searchReducer } from './search';
import seo from './seo';
import ui from './ui';
import user from './user';

interface HotJarState {}
type HotJarReducer = (
  state: HotJarState,
  action: Record<string, unknown>,
) => HotJarState;
const hotjarReducers: Record<string, HotJarReducer> = createHotjarReducers();

const searchWithTypeInfo = search as () => ReturnType<typeof searchReducer>;

export default combineReducers({
  appConfig,
  experiments,
  featureFlags,
  jobdetails,
  recentSearches,
  lmis,
  location,
  nudges,
  results,
  saveSearch,
  search: searchWithTypeInfo,
  seo,
  user,
  ui,
  banner,
  ...hotjarReducers,
});
