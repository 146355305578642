import startCase from 'lodash/startCase';

import { useAppConfig } from 'src/config/appConfig';
import { brandToCaseMap } from 'src/config/utils/constants';
import {
  getWorkArrangements,
  getWorkTypes,
} from 'src/modules/refine-job-search';
import { useSelector } from 'src/store/react';
import { selectResultsLocation, selectResultsCount } from 'src/store/selectors';

import { useClassifications } from './classifications';
import { useSearchParams } from './search-params';
import { toProperCase } from './utils';

export const useSearchResultsState = ({
  classRemainderFn,
  commaFormattedClassification,
}: {
  classRemainderFn?: (classificationCount: number) => string;
  commaFormattedClassification?: boolean;
} = {}) => {
  const searchParams = useSearchParams();
  const resultsLocation = useSelector(selectResultsLocation);
  const { brand } = useAppConfig();
  const formattedBrand = brandToCaseMap[brand];
  const keywords: string | null | undefined = toProperCase(
    searchParams?.keywords,
  );
  const classification: string | null | undefined =
    searchParams?.classification;
  const subclassification: string | null | undefined =
    searchParams?.subclassification;
  const workArrangement: string | null | undefined =
    searchParams?.workarrangement;
  const workType: string | null | undefined = searchParams?.worktype;
  const locationDescription: string = resultsLocation?.description || '';
  const shortLocationName: string | undefined =
    resultsLocation?.shortLocationName;

  const { zone, locale, language } = useAppConfig();
  const workTypeList = getWorkTypes(zone, language);

  const workTypeDescription = startCase(
    workTypeList?.find(({ value }) => value === workType)?.label,
  );

  const workArrangementList = getWorkArrangements(locale);
  const workArrangementDescription = startCase(
    workArrangementList?.find(({ id }) => id === workArrangement)?.label,
  );

  const classDescription: string = useClassifications({
    classification,
    subclassification,
    classRemainderFn,
    commaFormattedClassification,
  });
  const subclassOnlyDescription: string = useClassifications({
    classification: undefined,
    subclassification,
    classRemainderFn,
    commaFormattedClassification,
  });
  const jobCount = useSelector(selectResultsCount) || 0;

  return {
    brand: formattedBrand,
    keywords,
    locationDescription,
    workArrangementDescription,
    workTypeDescription,
    classDescription,
    subclassOnlyDescription,
    jobCount,
    shortLocationName,
  };
};
