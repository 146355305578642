import type { TypedAction } from '../types';

import { PAGE_LOADED, type UiState } from './types';

export const initialState: UiState = {
  isPageLoaded: false,
};

export default function reducer(
  state: UiState = initialState,
  action: TypedAction,
): UiState {
  switch (action.type) {
    case PAGE_LOADED: {
      const { isPageLoaded } = action.payload;
      return {
        ...state,
        isPageLoaded,
      };
    }

    default:
      return state;
  }
}

export const pageLoaded = (isPageLoaded = false): TypedAction => ({
  type: PAGE_LOADED,
  payload: {
    isPageLoaded,
  },
});
