import type { SearchParams } from '@seek/chalice-types';
import { useTranslations } from '@vocab/react';
import { useSelector } from 'react-redux';

import { selectCompanySuggestion } from 'src/store/selectors';

import { useSearchParams } from '../search-params';
import { useSearchResultsState } from '../state';
import { generateTranslationTypeKey } from '../utils';

import translations from './.vocab';

export const useCommaFormattedClassification = (searchParams: SearchParams) => {
  const numSearchParams = Object.keys(searchParams).length;
  const numSubclassifications =
    searchParams?.subclassification?.split(',').length || 0;
  const numParentClassifications =
    searchParams?.classification?.split(',').length || 0;
  const isLocationOrWorktypeSelected =
    Boolean(searchParams?.worktype) || Boolean(searchParams?.where);
  const isWorkarrangementSelected = Boolean(searchParams?.workarrangement);

  const isKeywordsSelected = Boolean(searchParams?.keywords);
  const isCommaFormattedClassWithLocationOrWorktypeSelected =
    numSearchParams === 3 &&
    numSubclassifications === 1 &&
    numParentClassifications === 1 &&
    isLocationOrWorktypeSelected;

  const isCommaFormattedClassWithWorkarrangementSelected =
    numSearchParams === 4 &&
    numSubclassifications === 1 &&
    numParentClassifications === 1 &&
    isWorkarrangementSelected &&
    !isKeywordsSelected;

  return (
    isCommaFormattedClassWithLocationOrWorktypeSelected ||
    isCommaFormattedClassWithWorkarrangementSelected
  );
};

export const useSearchResultsHeadDescription = ({
  isCompanySearch = false,
}: {
  isCompanySearch: boolean;
}): string => {
  const { t } = useTranslations(translations);
  const searchParams = useSearchParams();
  const shouldUseCommaFormattedClassification =
    useCommaFormattedClassification(searchParams);
  const {
    brand,
    jobCount,
    workArrangementDescription,
    workTypeDescription,
    classDescription,
    keywords,
    locationDescription,
  } = useSearchResultsState({
    classRemainderFn: (classificationCount) =>
      ` ${t('additional-classifications-description', {
        classificationCount,
      })}`, // Be wary of the space prefix here
    commaFormattedClassification: shouldUseCommaFormattedClassification,
  });
  const key: string = generateTranslationTypeKey({
    isCompanySearch,
    searchParams,
    classDescription,
    fallbackKey: 'noparams-description',
  });
  const pageDescription: string = useDescription({
    key,
    brand,
    jobCount,
    keywords,
    workArrangementDescription,
    workTypeDescription,
    classDescription,
    locationDescription,
  });

  return pageDescription;
};

const useDescription = ({
  key,
  brand,
  jobCount,
  keywords,
  workArrangementDescription,
  workTypeDescription,
  classDescription,
  locationDescription,
}: {
  key: string;
  brand: string;
  jobCount: number;
  keywords: string;
  workArrangementDescription: string;
  workTypeDescription: string;
  classDescription: string;
  locationDescription: string;
}): string => {
  const { t } = useTranslations(translations);
  const country = t('country-description');

  const companySuggestion = useSelector(selectCompanySuggestion);

  // SEAT-392 - temporary fix for Kindercare cases that discussed here:
  // https://seek-asia.slack.com/archives/C05P8EVTUEN/p1714111847723439?thread_ts=1713836329.765149&cid=C05P8EVTUEN
  // To be cleanup after complete logic is decided.
  const isKindercareKeyword =
    companySuggestion?.search.companyName?.toLowerCase() === 'kindercare';

  const workArrangementDescriptionTranslation = t(
    'workarrangement-description',
    {
      brand,
      jobCount,
      workArrangementDescription,
      country,
    },
  );

  const locationWorkArrangementDescriptionTranslation = t(
    'location-workarrangement-description',
    {
      brand,
      jobCount,
      workArrangementDescription,
      locationDescription,
    },
  );

  const descriptionTypes = {
    workarrangement: workArrangementDescriptionTranslation,

    multiworkarrangement: t('multiworkarrangement-description', {
      brand,
      jobCount,
      country,
    }),

    'classification-workarrangement': t(
      'classification-workarrangement-description',
      {
        brand,
        jobCount,
        workArrangementDescription,
        classDescription,
        country,
      },
    ),

    'classification-company-workarrangement': t(
      'classification-company-workarrangement-description',
      {
        brand,
        jobCount,
        workArrangementDescription,
        classDescription,
        country,
        keywords,
      },
    ),

    'classification-keywords-workarrangement': t(
      'classification-keywords-workarrangement-description',
      {
        brand,
        jobCount,
        workArrangementDescription,
        classDescription,
        country,
        keywords,
      },
    ),

    'classification-keywords-location-workarrangement':
      workArrangementDescriptionTranslation,

    'classification-keywords-workarrangement-worktype':
      workArrangementDescriptionTranslation,

    'classification-keywords-location-workarrangement-worktype':
      workArrangementDescriptionTranslation,

    'classification-location-workarrangement': t(
      'classification-location-workarrangement-description',
      {
        brand,
        jobCount,
        workArrangementDescription,
        classDescription,
        locationDescription,
      },
    ),

    'classification-company-location-workarrangement':
      workArrangementDescriptionTranslation,

    'classification-workarrangement-worktype': t(
      'classification-workarrangement-worktype-description',
      {
        brand,
        jobCount,
        workArrangementDescription,
        workTypeDescription,
        classDescription,
        country,
      },
    ),

    'classification-company-workarrangement-worktype':
      workArrangementDescriptionTranslation,

    'classification-location-workarrangement-worktype':
      workArrangementDescriptionTranslation,

    'classification-company-location-workarrangement-worktype':
      workArrangementDescriptionTranslation,

    'company-workarrangement': t('company-workarrangement-description', {
      brand,
      jobCount,
      workArrangementDescription,
      country,
      keywords,
    }),

    'keywords-workarrangement': t('keywords-workarrangement-description', {
      brand,
      jobCount,
      workArrangementDescription,
      country,
      keywords,
    }),

    'keywords-location-workarrangement': t(
      'keywords-location-workarrangement-description',
      {
        brand,
        jobCount,
        workArrangementDescription,
        locationDescription,
        keywords,
      },
    ),

    'keywords-workarrangement-worktype': t(
      'keywords-workarrangement-worktype-description',
      {
        brand,
        jobCount,
        workArrangementDescription,
        country,
        workTypeDescription,
        keywords,
      },
    ),

    'keywords-location-workarrangement-worktype':
      workArrangementDescriptionTranslation,

    'location-workarrangement': locationWorkArrangementDescriptionTranslation,

    'company-location-workarrangement': t(
      'company-location-workarrangement-description',
      {
        brand,
        jobCount,
        workArrangementDescription,
        locationDescription,
        keywords,
      },
    ),

    'workarrangement-worktype': t('workarrangement-worktype-description', {
      brand,
      jobCount,
      workArrangementDescription,
      country,
      workTypeDescription,
    }),

    'company-workarrangement-worktype': t(
      'company-workarrangement-worktype-description',
      {
        brand,
        jobCount,
        workArrangementDescription,
        country,
        workTypeDescription,
        keywords,
      },
    ),

    'location-workarrangement-worktype':
      locationWorkArrangementDescriptionTranslation,

    'company-location-workarrangement-worktype':
      workArrangementDescriptionTranslation,

    worktype: t('worktype-description', {
      brand,
      jobCount,
      workTypeDescription,
      country,
    }),
    multiworktype: t('multiworktype-description', { brand, jobCount, country }),
    'classification-worktype': t('classification-worktype-description', {
      brand,
      jobCount,
      workTypeDescription,
      classDescription,
      country,
    }),
    salarytype: t('salarytype-description', { brand, jobCount, country }),
    'salaryrange-salarytype': t('salaryrange-salarytype-description', {
      brand,
      jobCount,
      country,
    }),
    daterange: t('daterange-description', { brand, jobCount, country }),
    keywords: t(
      isKindercareKeyword
        ? 'keywords-description-kindercare'
        : 'keywords-description',
      {
        brand,
        jobCount,
        country,
        keywords,
      },
    ),
    'keywords-location': t(
      isKindercareKeyword
        ? 'keywords-location-description-kindercare'
        : 'keywords-location-description',
      {
        brand,
        jobCount,
        locationDescription,
        keywords,
      },
    ),
    'keywords-worktype': t(
      isKindercareKeyword
        ? 'keywords-worktype-description-kindercare'
        : 'keywords-worktype-description',
      {
        brand,
        jobCount,
        workTypeDescription,
        keywords,
        country,
      },
    ),
    'keywords-multiworktype': t(
      isKindercareKeyword
        ? 'keywords-multiworktype-description-kindercare'
        : 'keywords-multiworktype-description',
      {
        brand,
        jobCount,
        keywords,
        country,
      },
    ),
    'classification-keywords': t(
      isKindercareKeyword
        ? 'classification-keywords-kindercare'
        : 'classification-keywords',
      {
        brand,
        jobCount,
        classDescription,
        keywords,
        country,
      },
    ),
    location: t('location-description', {
      locationDescription,
      brand,
      jobCount,
    }),
    'location-worktype': t('location-worktype-description', {
      locationDescription,
      brand,
      jobCount,
    }),
    classification: t('classification-description', {
      classDescription,
      brand,
      jobCount,
      country,
    }),
    'classification-location': t('classification-location-description', {
      locationDescription,
      classDescription,
      brand,
      jobCount,
    }),
  };

  const noParamsDescription = t('noparams-description', {
    country,
    brand,
    jobCount,
  });

  return (
    descriptionTypes[key as keyof typeof descriptionTypes] ||
    noParamsDescription
  );
};
