import {
  JOB_DETAILS_FETCH_BEGIN,
  JOB_DETAILS_FETCH_SUCCESS,
  JOB_DETAILS_PAGE_LOADED,
  JOB_DETAILS_FETCH_FAILURE,
} from './jobdetails/types';
import { GET_LMIS_SUCCESS } from './lmis/types';
import { LOCATION_CHANGED, SET_PAGE_TITLE } from './location/types';
import {
  GET_RESULTS_BEGIN,
  GET_RESULTS_SUCCESS,
  MORE_OPTIONS,
} from './results/types';
import {
  GET_COUNTS_BEGIN,
  GET_COUNTS_SUCCESS,
  SUBMIT_SEARCH,
  UPDATE_CRITERIA,
} from './search/types';
import { SET_CANONICAL_URL } from './seo/types';
import { GET_ACCOUNT_SUCCESS, UPDATE_SESSION } from './user/types';

const HOTJAR_QUEUE_TAGS = '@@REDUX_HOTJAR_QUEUE_TAGS';
const EXPERIMENTS_INITIALISED = '__EXPERIMENTS_INITIALISED';

// These cannot be exported as POJOs because in causes circular
// dependencies and hence a race condition so using functions to access.
const transitions = {
  routeChange() {
    return {
      name: 'routeChange',
      allowedActions: [
        HOTJAR_QUEUE_TAGS,
        EXPERIMENTS_INITIALISED,
        GET_ACCOUNT_SUCCESS,
        GET_COUNTS_BEGIN,
        GET_COUNTS_SUCCESS,
        GET_LMIS_SUCCESS,
        GET_RESULTS_BEGIN,
        GET_RESULTS_SUCCESS,
        JOB_DETAILS_FETCH_BEGIN,
        JOB_DETAILS_FETCH_SUCCESS,
        JOB_DETAILS_FETCH_FAILURE,
        JOB_DETAILS_PAGE_LOADED,
        LOCATION_CHANGED,
        MORE_OPTIONS,
        SET_CANONICAL_URL,
        SET_PAGE_TITLE,
        SUBMIT_SEARCH,
        UPDATE_CRITERIA,
        UPDATE_SESSION,
      ],
    };
  },
};

export function getTransaction(transactionName: keyof typeof transitions) {
  const transitionFunc = transitions[transactionName];
  return transitionFunc();
}
