export const PAGE_LOADED = 'PAGE_LOADED';

export interface UiState {
  isPageLoaded: boolean;
}

export interface PageLoadedAction {
  type: typeof PAGE_LOADED;
  payload: {
    isPageLoaded: boolean;
  };
}

export type UiAction = PageLoadedAction;
