import type { ApolloQueryResult } from '@apollo/client';
import { useEffect } from 'react';
// @ts-expect-error
import { provideHooks } from 'redial';
import loadable from 'sku/@loadable/component';

import { SafetyHelmet } from 'src/components/SafetyHelmet/SafetyHelmet';
import { BannerPlacement } from 'src/graphql/graphql';
import { useIntercom } from 'src/hooks/useIntercom';
import { useSaveJobFromQueryParam } from 'src/hooks/useSaveJob/utils/useSaveJobFromQueryParam';
import { SEARCH_SAVED_AND_APPLIED_JOBS } from 'src/modules/graphql/queries/savedJobs';
import type {
  SearchSavedAndAppliedJobs,
  SearchSavedAndAppliedJobsVariables,
} from 'src/modules/graphql/queries/types/SearchSavedAndAppliedJobs';
import { logErrorToRaygun } from 'src/modules/raygun-logger';
import { useSearchResultsHead } from 'src/modules/seo-header';
import { getBanner } from 'src/store/banner';
import { clearJobDetails } from 'src/store/jobdetails';
import { useDispatch, useSelector } from 'src/store/react';
import { getResults, saveSolReferencesAction } from 'src/store/results';
import { persistCriteriaLocally, expandSearchFilters } from 'src/store/search';
import {
  selectLocation,
  selectSearchPerformedAnalyticsData,
} from 'src/store/selectors';
import { pageLoaded } from 'src/store/ui';
import type { RedialLocals } from 'src/types/RedialLocals';
import { isUuid } from 'src/utils/uuid';

import { useSplitView, SplitViewContextProvider } from './SplitViewContext';

export const SearchResultPage = loadable(
  /* #__LOADABLE__ */ () =>
    import(
      /* webpackChunkName: "SearchResultPage" */ 'src/components/SearchResultPage/SearchResultPage'
    ),
  {
    resolveComponent: (m) => m.default,
  },
);

const hooks = {
  fetch: ({
    analyticsFacade,
    brand,
    dispatch,
    state,
    getState,
    country,
    zone,
    path,
    query,
    cookies,
    referrer,
    userAgent,
    visitorId,
    languageCode,
    locale,
    xRealIp,
  }: RedialLocals) => {
    const { paginationParameters = {} } = state;
    const searchArgs = {
      brand,
      country,
      locale,
      zone,
      path,
      query,
      paginationParameters,
      cookies,
      userAgent,
      visitorId,
      languageCode,
      xRealIp,
    };
    dispatch(expandSearchFilters(true));
    return dispatch(getResults(searchArgs, referrer)).then(() => {
      const payload = selectSearchPerformedAnalyticsData(getState());
      analyticsFacade.searchPerformed(payload);
    });
  },
  defer: ({ dispatch, routeEnter }: RedialLocals) => {
    if (routeEnter && window.hj) {
      window.hj('trigger', 'search-results-all-browsers');
    }
    return dispatch(persistCriteriaLocally());
  },
  mashup: ({ getState, authenticated, apolloClient }: RedialLocals) => {
    const { results } = getState();
    const jobIds = results.jobIds.filter((jobId) => Boolean(jobId));

    return authenticated && jobIds.length > 0
      ? apolloClient
          .query<SearchSavedAndAppliedJobs, SearchSavedAndAppliedJobsVariables>(
            {
              query: SEARCH_SAVED_AND_APPLIED_JOBS,
              variables: {
                jobIds,
              },
            },
          )
          .then((response: ApolloQueryResult<SearchSavedAndAppliedJobs>) => {
            if (response === null) {
              logErrorToRaygun({
                error: new Error(
                  'SEARCH_SAVED_AND_APPLIED_JOBS - null graphql response',
                ),
              });
            }

            if (response.data.viewer === null) {
              logErrorToRaygun({
                error: new Error('SEARCH_SAVED_AND_APPLIED_JOBS - null viewer'),
              });
            }
          })
          .catch((error: Error) => {
            logErrorToRaygun({
              error: new Error(error.message),
            });
          })
      : null;
  },

  pageLoad: ({ dispatch, getState, apolloClient, cookies }: RedialLocals) => {
    const {
      appConfig: {
        zoneFeatures: { LMIS_ENABLED },
      },
    } = getState();

    if (LMIS_ENABLED) {
      dispatch(
        getBanner({
          apolloClient,
          placement: BannerPlacement.SerpFooterLinks,
          visitorId: isUuid(cookies.JobseekerVisitorId)
            ? cookies.JobseekerVisitorId
            : undefined,
        }),
      );
    }

    dispatch(saveSolReferencesAction());
    return dispatch(pageLoaded(true));
  },
};

function useClearJobDetailsOnLocationChange() {
  const dispatch = useDispatch();
  const currentUrl = useSelector(selectLocation).url;

  // clear job details for splitview
  useEffect(() => {
    const action = clearJobDetails();
    dispatch(action);
  }, [currentUrl, dispatch]);
}

const SearchResults = () => {
  useEffect(() => {
    window.hj =
      window.hj ||
      function () {
        // eslint-disable-next-line prefer-rest-params
        (hj.q = hj.q || []).push(arguments);
      };
  });

  useIntercom();

  useClearJobDetailsOnLocationChange();

  useSaveJobFromQueryParam();

  const helmetData = useSearchResultsHead();

  const splitViewContextValue = useSplitView();

  return (
    <>
      <SafetyHelmet {...helmetData} key="head" />
      <SplitViewContextProvider value={splitViewContextValue}>
        <SearchResultPage key="body" />
      </SplitViewContextProvider>
    </>
  );
};

export default provideHooks(hooks)(SearchResults);
