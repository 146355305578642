import type { SearchResultJob } from '@seek/chalice-types';
import { JOB_DISPLAY_TYPE_QUERY_KEY } from '@seek/job-details-view';
import { enrichLocation } from '@seek/seek-jobs-seo';

import { SELECTED_JOB_ID_SEARCH_QUERY_KEY } from 'src/store/results';
import type { selectLocation } from 'src/store/selectors';

export const persistJobUrl = ({
  currentLocation,
  job: { id, displayType },
}: {
  currentLocation: ReturnType<typeof selectLocation>;
  job: Pick<SearchResultJob, 'id' | 'displayType'>;
}) => {
  const targetUrl =
    enrichLocation({
      ...currentLocation,
      query: {
        ...currentLocation.query,
        [SELECTED_JOB_ID_SEARCH_QUERY_KEY]: id,
        [JOB_DISPLAY_TYPE_QUERY_KEY]: displayType,
      },
    }).href ?? '';

  if (targetUrl && currentLocation?.url) {
    history.replaceState(
      {},
      '',
      targetUrl.replace(/(^\w+:)/, new URL(currentLocation.url).protocol),
    );
  } else {
    history.replaceState({}, '', targetUrl);
  }
};

export const removePersistedJobUrl = (
  currentLocation: ReturnType<typeof selectLocation>,
) => {
  if (currentLocation?.url) {
    const url = new URL(currentLocation.url);
    url.searchParams.delete(SELECTED_JOB_ID_SEARCH_QUERY_KEY);
    url.searchParams.delete(JOB_DISPLAY_TYPE_QUERY_KEY);
    history.replaceState({}, '', url.href);
  }
};
