import type { SmarterSearchCluster } from 'src/config/types';

export const UPDATE_FEATURE_FLAGS = 'UPDATE_FEATURE_FLAGS';

export interface FeatureFlagsState {
  behaviouralCues: boolean;
  branchBannerPreview: boolean;
  isBranchEnabledFlag: boolean;
  retireDisplayAds: boolean;
  smarterSearchLaunchCluster: SmarterSearchCluster;
  seoMetaTags: boolean;
  showHomepageBanner: boolean;
  showFloatingSaveSearch: boolean;
  autoSelectSplitViewFirstJob: boolean;
  showMarketingAdSplitView: boolean;
  loggedOutRecs: boolean;
  remoteSearchFilter: boolean;
  remoteSearchFilterNewLabel: boolean;
  dynamicPills: boolean;
  secondaryFilters: boolean;
  v5JobSearch: boolean;
  keywordAutosuggestV2: boolean;
  hirerVerifiedBadge: boolean;
  serpJobCardInfoDensity1: boolean;
  newJobCardDensity: boolean;
  hideCompanyLogo: boolean;
}

export interface FeatureFlagsAction {
  type: typeof UPDATE_FEATURE_FLAGS;
  payload: {
    dynamicFlags: Record<string, any>;
  };
}

export type TFeatureFlagNames = keyof FeatureFlagsState;
