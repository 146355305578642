import type { ChaliceStore } from '../types';

export const selectSearchQuery = (state: ChaliceStore) => state.search.query;
export const selectClearCompanyRefinements = (state: ChaliceStore) =>
  state.search.refinements.clear?.company;
export const selectIsFiltersExpanded = (state: ChaliceStore) =>
  state.search.filtersExpanded;
export const selectKeywordField = (state: ChaliceStore) =>
  state.search.keywordsField;
export const selectWhereField = (state: ChaliceStore) =>
  state.search.whereField;
