import type {
  JobProfileMissingSalaryPreference,
  JobProfileSalaryMatch,
  JobProfileSalaryNoMatch,
  LearningInsights,
  JobDetails as _JobDetails,
} from '@seek/ca-graphql-schema/types';

export const JOB_DETAILS_FETCH_BEGIN = 'JOB_DETAILS_FETCH_BEGIN';
export const JOB_DETAILS_FETCH_SUCCESS = 'JOB_DETAILS_FETCH_SUCCESS';
export const JOB_DETAILS_FETCH_FAILURE = 'JOB_DETAILS_FETCH_FAILURE';

export const JOB_DETAILS_PERSONALISED_FETCH_SUCCESS =
  'JOB_DETAILS_PERSONALISED_FETCH_SUCCESS';
export const RESET_JOB_DETAILS = 'RESET_JOB_DETAILS';
export const JOB_DETAILS_PAGE_LOADED = 'JOB_DETAILS_PAGE_LOADED';

export const JOB_FRAUD_REPORT_SUCCESS = 'JOB_FRAUD_REPORT_SUCCESS';
export const JOB_FRAUD_REPORT_FAILURE = 'JOB_FRAUD_REPORT_FAILURE';

export const CIS_IMPRESSION = 'CIS_IMPRESSION';
export const CLEAR_JOB_DETAILS = 'CLEAR_JOB_DETAILS';

export declare interface Analytics$CISImpression {
  matches: string[];
}

// Todo: Fix JobDetails types
export type JobDetails =
  | _JobDetails & {
      learningInsights:
        | (LearningInsights & { analytics: Record<string, string> })
        | null;
      personalised?: PersonalisedJobDetails;
    };

// Todo: Fix JobDetails types
export type PersonalisedJobDetails = {
  isSaved: boolean | null;
  appliedDateTime: {
    shortAbsoluteLabel: string;
  } | null;
  topApplicantBadge: {
    label: string;
    description: string;
  } | null;
  salaryMatch:
    | (JobProfileSalaryMatch & { __typename: 'JobProfileSalaryMatch' })
    | (JobProfileSalaryNoMatch & { __typename: 'JobProfileSalaryNoMatch' })
    | (JobProfileMissingSalaryPreference & {
        __typename: 'JobProfileMissingSalaryPreference';
      })
    | null;
} | null;

export interface JobDetailsState {
  error?: boolean;
  fraudReport: any;
  jobDetailsViewedCorrelationId?: string;
  jobPending: boolean;
  pageLoadedCount: number;
  personalised?: PersonalisedJobDetails;
  result: JobDetails | null;
  xRealIp: string | undefined;
}

export interface JobDetailsFetchFailureAction {
  type: typeof JOB_DETAILS_FETCH_FAILURE;
  payload: any;
}

export interface JobDetailsPageLoadedAction {
  type: typeof JOB_DETAILS_PAGE_LOADED;
}

export interface ClearJobDetailsAction {
  type: typeof CLEAR_JOB_DETAILS;
}

export interface CisImpressionAction {
  type: typeof CIS_IMPRESSION;
  payload: Analytics$CISImpression;
  meta: Record<string, unknown>;
}

export type JobDetailsAction =
  | JobDetailsFetchFailureAction
  | { type: typeof JOB_DETAILS_FETCH_BEGIN }
  | {
      type: typeof JOB_DETAILS_FETCH_SUCCESS;
      payload: { result: JobDetails; xRealIp: string | undefined };
    }
  | {
      type: typeof JOB_DETAILS_PERSONALISED_FETCH_SUCCESS;
      payload: {
        personalised: PersonalisedJobDetails;
        jobDetailsViewedCorrelationId: string;
        xRealIp: string | undefined;
      };
    }
  | { type: typeof RESET_JOB_DETAILS }
  | JobDetailsPageLoadedAction
  | { type: typeof JOB_FRAUD_REPORT_SUCCESS }
  | { type: typeof JOB_FRAUD_REPORT_FAILURE }
  | ClearJobDetailsAction
  | CisImpressionAction;
