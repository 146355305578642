import type { Zone } from '@seek/audience-zones';
import { isCancelled } from '@seek/ca-http-client';
import type {
  SearchParams,
  SearchResult,
  SearchResultJob,
  SearchResultLocation,
  SearchResultLocationSuggestion,
} from '@seek/chalice-types';
import { hashToUserQueryId } from '@seek/seek-jobs-analytics';
import { normaliseSearchQuery } from '@seek/seek-jobs-seo';
import { addJobDetailsMetadata, addTags } from '@seek/sol-js';
import type { AxiosError } from 'axios';
import get from 'lodash/get';
import includes from 'lodash/includes';
import uniq from 'lodash/uniq';

import type { SmarterSearchCluster } from 'src/config/types';
import isSuburb from 'src/modules/locations';
import { logger } from 'src/modules/logger';
// @ts-expect-error: non-ts file
import pixelPromise from 'src/modules/pixel-promise';
import {
  getClassificationName,
  searchResultsViewModel,
} from 'src/modules/refine-job-search';
import { searchResultsPageRegex } from 'src/modules/routes-regexp';
import { isTimeoutError } from 'src/modules/seek-api-request';
import createApiClient from 'src/modules/seek-jobs-api-client';
import { setPageTitle } from 'src/store/location';
import { CLEAR_NUDGE_ERROR } from 'src/store/nudges/types';
import { SUBMIT_SEARCH } from 'src/store/search/types';
import type { TestHeaders } from 'src/utils/productionTesting/productionTesting';

import { selectExperimentsAsSolTags } from '../experiments/experimentHelpers';
import {
  selectFeatureFlag,
  selectSmarterSearchCluster,
} from '../featureFlags/selectors';
import { LOCATION_CHANGED } from '../location/types';
import type { ChaliceStore, TypedAction, TypedThunkAction } from '../types';
import { selectAuthenticated } from '../user/selectors';

// @ts-expect-error: non-ts file
import { getDecodedTokenWithoutIndex } from './results.token';
import {
  CLEAR_RESULTS,
  FETCH_SEARCH_RESULTS_ERROR,
  GET_RESULTS_BEGIN,
  GET_RESULTS_ERROR,
  GET_RESULTS_ERROR_CANCELLATION,
  GET_RESULTS_SUCCESS,
  JORA_RESULT_LINK_CLICKED,
  MORE_OPTIONS,
  RESULT_LINK_CLICKED,
  SAVE_SOL_REFERENCES,
  SAVE_SOL_REFERENCES_FOR_LOGGED_IN_HOME_RECS,
  SAVE_SOL_REFERENCES_FOR_LOGGED_OUT_HOME_RECS,
  SET_SELECTED_JOB_ID,
  type EnrichedSearchViewModel,
  type GetResultsSuccessAction,
  type RelatedSearch,
  type ResultsState,
  type SaveSolReferencesAction,
  type SaveSolReferencesForLoggedInRecsAction,
  type SaveSolReferencesForLoggedOutRecsAction,
  type SearchViewModel,
  type SetSelectedJobIdAction,
  type SolReferenceKeyLookup,
} from './types';

const api = createApiClient();

const EMPTY_RELATED_SEARCH: RelatedSearch[] = [];

export const SELECTED_JOB_ID_SEARCH_QUERY_KEY = 'jobId';

export const initialState: ResultsState = {
  results: null,
  isLoading: false,
  isError: false,
  source: '',
  companySuggestion: undefined,
  locationWhere: null,
  title: '',
  totalCount: null,
  totalPages: 0,
  jobIds: [],
  lastPage: 0,
  paginationParameters: {},
  sortMode: null,
  solReferenceKeys: [],
  hidden: false,
  relatedSearches: EMPTY_RELATED_SEARCH,
  facets: {},
};

export interface CalculateResultsArgs {
  query?: SearchParams | any;
  results?: SearchResultJob[] | null;
  location?: SearchResultLocation;
  totalCount?: number;
  suggestions?: SearchResultLocationSuggestion[];
}

const calculateResults = (
  args: CalculateResultsArgs = {},
): EnrichedSearchViewModel => {
  const {
    query = {},
    results = null,
    location,
    totalCount,
    suggestions,
  } = args;
  const page = query.page || '1';

  const { jobs, tiers, ...viewModel } = searchResultsViewModel({
    page,
    results,
    location,
    totalCount,
    suggestions,
    searchParams: query,
  }) as SearchViewModel;

  const combinedJobs = [...(jobs || []), ...((tiers && tiers[0].jobs) || [])];

  return {
    ...viewModel,
    jobs: combinedJobs.length > 0 ? combinedJobs : null,
    suburbs: results
      ? uniq(results.map((job) => job.suburbWhereValue ?? '').filter(Boolean))
      : [],
  };
};

export default function reducer(
  state: ResultsState = initialState,
  action: TypedAction,
): ResultsState {
  switch (action.type) {
    case MORE_OPTIONS: {
      return {
        ...state,
        hidden: true,
      };
    }

    case CLEAR_RESULTS:
    case GET_RESULTS_BEGIN: {
      const { query = {} }: any = action.payload || {};

      return {
        ...state,
        results: calculateResults({ query }),
        jobIds: [],
        source: '',
        isLoading: true,
        isError: false,
        companySuggestion: undefined,
        relatedSearches: EMPTY_RELATED_SEARCH,
        hidden: false,
      };
    }

    case SUBMIT_SEARCH: {
      return {
        ...state,
        isLoading: true,
        solReferenceKeys: [],
      };
    }

    case LOCATION_CHANGED: {
      const { pathname, query } = action.payload.location;
      const isSearchResultPage = searchResultsPageRegex.test(pathname || '');
      const isHomepage = pathname === '/';
      const homePageUpdates = isHomepage
        ? {
            title: '', // Use default localised title for home page
            results: null, // Reset results to get a fresh list when returning to SERP
          }
        : {};
      return {
        ...state,
        ...homePageUpdates,
        selectedJobId: isSearchResultPage
          ? query?.[SELECTED_JOB_ID_SEARCH_QUERY_KEY]
          : undefined,
      };
    }

    case GET_RESULTS_SUCCESS: {
      const { query, currentPageNumber, userQueryId, relatedSearches } =
        action.payload;

      const {
        title,
        data,
        totalCount,
        location,
        suggestions,
        paginationParameters = {},
        sortMode = null,
        canonicalCompany,
        companySuggestion,
        joraCrossLink,
        info,
        solMetadata,
        facets,
        pills,
      } = action.payload.result;

      const locationWhere =
        location === undefined ||
        location.description === 'All Australia' ||
        location.description === 'All New Zealand'
          ? ''
          : location.description;

      const tracking = get(action.payload, 'result.data[0].tracking');
      const uniqueSearchToken = getDecodedTokenWithoutIndex(tracking);
      const { source }: any = info || {};
      const solMetadataString = JSON.stringify(solMetadata || {});
      const isRadialFilterShown = isSuburb(location);
      const isRadialFilterNudgeShown =
        currentPageNumber === 2 &&
        totalCount >= 50 &&
        isRadialFilterShown &&
        !query.hasOwnProperty('distance');
      const jobIds = data
        ? data.map((job: SearchResultJob) => `${job.id}`)
        : [];

      return {
        ...state,
        results: calculateResults({
          query,
          results: data,
          location,
          totalCount,
          suggestions,
        }),
        jobIds,
        isLoading: false,
        isError: false,
        source,
        companySuggestion,
        canonicalCompany,
        joraCrossLink,
        location,
        locationWhere,
        title,
        totalCount,
        userQueryId,
        paginationParameters,
        sortMode,
        relatedSearches,
        uniqueSearchToken,
        solMetadata,
        solMetadataString,
        isRadialFilterShown,
        isRadialFilterNudgeShown,
        facets,
        pills,
      };
    }

    case GET_RESULTS_ERROR: {
      return {
        ...state,
        results: calculateResults(),
        jobIds: [],
        source: '',
        isLoading: false,
        isError: true,
        title: 'Error loading results',
        lastPage: undefined,
        relatedSearches: EMPTY_RELATED_SEARCH,
      };
    }

    case SAVE_SOL_REFERENCES: {
      const jobs = state.results?.jobs || [];
      const refs = !ENV.SERVER
        ? addJobDetailsMetadata(jobs).map<SolReferenceKeyLookup>(
            (key, index) => {
              const { id, displayType } = jobs[index];
              return {
                hash: key,
                displayType,
                id,
              };
            },
          )
        : [];
      return {
        ...state,
        solReferenceKeys: refs,
      };
    }

    case SAVE_SOL_REFERENCES_FOR_LOGGED_OUT_HOME_RECS:
    case SAVE_SOL_REFERENCES_FOR_LOGGED_IN_HOME_RECS: {
      const jobs = action.payload.recommendations;
      const refs = !ENV.SERVER
        ? addJobDetailsMetadata(jobs).map<SolReferenceKeyLookup>(
            (key, index) => {
              const {
                job: { id },
              } = jobs[index];
              return {
                hash: key,
                displayType:
                  action.type === SAVE_SOL_REFERENCES_FOR_LOGGED_OUT_HOME_RECS
                    ? 'loggedOutHomeRecs'
                    : 'loggedInHomeRecs',
                id: Number(id),
              };
            },
          )
        : [];
      return {
        ...state,
        solReferenceKeys: refs,
      };
    }

    case SET_SELECTED_JOB_ID: {
      const { jobId } = action.payload;
      return {
        ...state,
        selectedJobId: jobId,
      };
    }

    default: {
      return state;
    }
  }
}

export const moreOptions = (): TypedAction => ({
  type: MORE_OPTIONS,
  meta: {
    hotjar: 'More Options Clicked',
  },
});

export const getRelatedSearchTerm = ({
  companyname,
  keywords,
  classification,
  subclassification,
  zone,
  languageCode,
}: any) => {
  const getFirstClassificationNameFromSortedList = (str = '') =>
    getClassificationName(Number(str.split(',').sort()[0]), zone, languageCode);
  const lower = (str = '') => str.toLowerCase();

  return (
    lower(keywords) ||
    lower(companyname) ||
    lower(getFirstClassificationNameFromSortedList(subclassification)) ||
    lower(getFirstClassificationNameFromSortedList(classification)) ||
    false
  );
};

const fetchSearchResults =
  ({
    apiQuery = {},
    brand,
    country = 'AU',
    zone = 'anz-1',
    cookies,
    userQueryId,
    tries = 2,
    timeout,
    userAgent,
    visitorId,
    xRealIp,
    isAuthenticated = false,
    isV5Search = false,
  }: any): TypedThunkAction<Promise<SearchResult>> =>
  (dispatch, getState) => {
    const state = getState();
    const {
      user: { testHeaders },
      appConfig: { zoneFeatures, locale },
    } = state;

    return api.jobs
      .search({
        brand,
        searchParams: { ...apiQuery, pageSize: zoneFeatures?.SEARCH_PAGE_SIZE },
        country,
        zone,
        cookies,
        userQueryId,
        requestId: state.location.requestId,
        timeout,
        userAgent,
        seekerId: get(state, 'user.seekerId'),
        solId: visitorId,
        testHeaders,
        locale,
        xRealIp,
        isAuthenticated,
        isV5Search,
      })
      .catch((err: AxiosError) => {
        dispatch({
          type: FETCH_SEARCH_RESULTS_ERROR,
          meta: {
            metrics: {
              name: FETCH_SEARCH_RESULTS_ERROR,
            },
          },
        });

        const retryUserQueryId = hashToUserQueryId({
          query: apiQuery.normalisedQuery,
        });

        if (get(err, 'response.status') === 400) {
          return {
            totalCount: 0,
            data: [],
          } as any;
        }

        if (isTimeoutError(err) || get(err, 'response.status', 0) >= 500) {
          if (tries === 1) {
            throw err;
          }

          return fetchSearchResults({
            apiQuery,
            country,
            zone,
            cookies,
            userQueryId: retryUserQueryId,
            visitorId,
            tries: tries - 1,
            xRealIp,
            isAuthenticated,
            isV5Search,
          })(dispatch, getState, null);
        }

        throw err;
      });
  };

type RelatedSearchTerm = string | false;

const fetchRelatedSearches = ({
  countryCode,
  zone,
  relatedSearchTerm,
  where,
  requestId,
  testHeaders,
  cluster,
  visitorId,
}: {
  countryCode: string;
  zone: Zone;
  relatedSearchTerm: RelatedSearchTerm;
  where?: string;
  requestId?: string;
  testHeaders?: TestHeaders;
  cluster: SmarterSearchCluster;
  visitorId: string;
}) => {
  if (!relatedSearchTerm) {
    return Promise.resolve(EMPTY_RELATED_SEARCH);
  }
  return api.jobs
    .relatedSearches({
      zone,
      keywords: relatedSearchTerm,
      where,
      requestId,
      timeout: 3000,
      testHeaders: testHeaders || {},
      countryCode: countryCode.toLowerCase(),
      cluster,
      visitorId,
    })
    .then((result: any) =>
      result ? result.relatedSearches : EMPTY_RELATED_SEARCH,
    )
    .catch(() => {
      // ignore error because don't want to block search
    });
};

export const getResults =
  (
    {
      brand,
      country,
      zone,
      path,
      query,
      paginationParameters = {},
      cookies,
      userAgent,
      visitorId,
      languageCode,
      xRealIp,
    }: any,
    referrer: any,
  ): TypedThunkAction<Promise<void>> =>
  (dispatch, getState) => {
    const state = getState();
    const cluster = selectSmarterSearchCluster(state);
    const normalisedQuery = normaliseSearchQuery({ path, query });
    const apiQuery = { ...normalisedQuery, ...paginationParameters };
    const userQueryId = hashToUserQueryId({ query: normalisedQuery });
    const relatedSearchTerm = getRelatedSearchTerm({
      ...apiQuery,
      zone,
      languageCode,
    });
    const isAuthenticated = selectAuthenticated(state);
    const isV5Search = selectFeatureFlag('v5JobSearch')(state);

    const where = apiQuery.where || undefined;

    dispatch({ type: CLEAR_NUDGE_ERROR });

    dispatch({
      type: GET_RESULTS_BEGIN,
      payload: {
        query: normalisedQuery,
        metrics: {
          name: GET_RESULTS_BEGIN,
        },
      },
    });

    const { requestId } = getState().location;
    const fetchData = Promise.all([
      fetchSearchResults({
        apiQuery,
        brand,
        country,
        zone,
        cookies,
        userQueryId,
        requestId,
        userAgent,
        visitorId,
        xRealIp,
        isAuthenticated,
        isV5Search,
      })(dispatch, getState, null),
      fetchRelatedSearches({
        countryCode: country,
        zone,
        relatedSearchTerm,
        where,
        requestId,
        testHeaders: getState().user.testHeaders,
        cluster,
        visitorId,
      }),
    ]);

    return fetchData
      .then(([result, relatedSearches]) => {
        const currentPageNumber = getState().location.pageNumber;
        const totalCount = get(result, 'totalCount', 0);
        const zeroResults = totalCount === 0;
        const hotjarTags = zeroResults ? ['Zero Results'] : [];
        const referrerTag = includes(referrer, 'google')
          ? { referrer: 'google' }
          : {};

        const experimentTags = selectExperimentsAsSolTags(getState());

        const mappedResult = {
          ...result,
          data: result.data.map((jobData) => ({
            ...jobData,
            branding: jobData?.branding,
            solMetadata: addTags(jobData.solMetadata, experimentTags),
          })),
        };

        const action: GetResultsSuccessAction = {
          type: GET_RESULTS_SUCCESS,
          payload: {
            query: normalisedQuery,
            result: {
              ...mappedResult,
              // ensure the experiments are added to solmetadata
              solMetadata: {
                ...addTags(mappedResult.solMetadata, {
                  ...experimentTags,
                }),
                query: normalisedQuery,
              },
            },
            currentPageNumber,
            userQueryId,
            relatedSearches,
          },
          meta: {
            metrics: {
              name: GET_RESULTS_SUCCESS,
              tags: {
                zeroResults,
                companySearch: Boolean(get(result, 'companySuggestion')),
                keywordSearch: Boolean(normalisedQuery.keywords),
                ...referrerTag,
              },
            },
            hotjar: hotjarTags,
          },
        };
        dispatch(action);

        const { title } = getState().results;
        dispatch(setPageTitle(title));
      })
      .catch((err) => {
        if (isCancelled(err)) {
          dispatch({
            type: GET_RESULTS_ERROR_CANCELLATION,
            error: true,
            meta: {
              metrics: {
                name: GET_RESULTS_ERROR_CANCELLATION,
              },
            },
          });
        }
        logger.error({ err }, 'fetchData promise was cancelled');
        throw err;
      })
      .catch((err) => {
        dispatch({
          type: GET_RESULTS_ERROR,
          error: true,
          payload: err,
          meta: {
            metrics: {
              name: GET_RESULTS_ERROR,
            },
          },
        });
        logger.error({ err }, 'Failed to fetch search data');
      });
  };

export const resultLinkClicked =
  (job: any): TypedThunkAction =>
  (dispatch) => {
    const { joraClickTrackingUrl } = job;

    dispatch({
      type: RESULT_LINK_CLICKED,
      meta: {
        metrics: {
          name: RESULT_LINK_CLICKED,
        },
      },
    });

    if (joraClickTrackingUrl) {
      dispatch({
        type: JORA_RESULT_LINK_CLICKED,
        meta: {
          metrics: {
            name: JORA_RESULT_LINK_CLICKED,
          },
        },
      });

      return pixelPromise(joraClickTrackingUrl);
    }
    return null;
  };

export const saveSolReferencesAction = (): SaveSolReferencesAction => ({
  type: SAVE_SOL_REFERENCES,
});

export const saveSolReferencesForLoggedOutHomeRecsAction = (
  recommendations: SaveSolReferencesForLoggedOutRecsAction['payload']['recommendations'],
): SaveSolReferencesForLoggedOutRecsAction => ({
  type: SAVE_SOL_REFERENCES_FOR_LOGGED_OUT_HOME_RECS,
  payload: { recommendations },
});

export const saveSolReferencesForLoggedInHomeRecsAction = (
  recommendations: SaveSolReferencesForLoggedInRecsAction['payload']['recommendations'],
): SaveSolReferencesForLoggedInRecsAction => ({
  type: SAVE_SOL_REFERENCES_FOR_LOGGED_IN_HOME_RECS,
  payload: { recommendations },
});

export const setSelectedJobIdAction = (
  jobId: SetSelectedJobIdAction['payload']['jobId'],
): SetSelectedJobIdAction => ({
  type: SET_SELECTED_JOB_ID,
  payload: { jobId },
});

export const clearResults = (): TypedAction => ({
  type: CLEAR_RESULTS,
});

export { fetchSearchResults as fetchSearchResultsForTest };

export const createJobLinkSolReferenceSelector =
  ({
    jobId,
    displayType,
  }: {
    jobId: SolReferenceKeyLookup['id'];
    displayType: SolReferenceKeyLookup['displayType'];
  }) =>
  (state: ChaliceStore) => {
    const match = state.results.solReferenceKeys.find(
      (ref) => ref.id === jobId && ref.displayType === displayType,
    );
    return match?.hash;
  };
